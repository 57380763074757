/**
 *
 * TYPE constants for Card Details Context
 *
 *
 */
const SET_MODAL_VIEW = 'SET_MODAL_VIEW';
const SET_AMOUNT = 'SET_AMOUNT';
const SET_PAYMENT_STATE = 'SET_PAYMENT_STATE';
const SET_FEES_AMOUNT = 'SET_FEES_AMOUNT';
const SET_FEES = 'SET_FEES';
const SET_REFERRER = 'SET_REFERRER';
const CLOSE_MODAL = 'CLOSE_MODAL';
const CLEAR_FORMS = 'CLEAR_FORMS';
const CLEAR_ADDRESS = 'CLEAR_ADDRESS';

const SET_SELLER_DATA = 'SET_SELLER_DATA';
const SET_LUC_DATA = 'SET_LUC_DATA';
const UPDATE_SELLER_DATA = 'UPDATE_SELLER_DATA';

const EMAIL_REGEX = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;

export {
  SET_MODAL_VIEW,
  SET_AMOUNT,
  SET_PAYMENT_STATE,
  SET_FEES_AMOUNT,
  SET_FEES,
  SET_REFERRER,
  CLOSE_MODAL,
  CLEAR_FORMS,
  EMAIL_REGEX,
  SET_SELLER_DATA,
  UPDATE_SELLER_DATA,
  SET_LUC_DATA,
  CLEAR_ADDRESS,
};
