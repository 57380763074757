enum ScreenType {
  Track,
  Rewards,
  Dashboard,
  Claim,
  LyftCode,
  Upload,
  Email,
}

export default ScreenType;
