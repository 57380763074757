/**
 *
 * TYPE constants for Voucher Context
 *
 *
 */
const SET_VIEW = 'SET_VIEW';
const SET_AMOUNT = 'SET_AMOUNT';
const SET_VOUCHER_INFO = 'SET_VOUCHER_INFO';

export { SET_VIEW, SET_AMOUNT, SET_VOUCHER_INFO };
